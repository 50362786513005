<template>
  <div class="checkout-processing">
    <div>
      <h3>Processing transaction</h3>
      <p>This might take a few seconds</p>
    </div>
    <i class="spinner centered"></i>
  </div>
</template>

<style lang="scss" scoped>
.spinner.centered {
  margin: 0 auto;
}

.spinner.black::before {
  color: var(--backgroundColor);
}

.checkout-processing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  > * + * {
    margin-top: 1rem;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-size: 0.8rem;
    text-align: center;
    color: var(--disabledColor);
  }
}
</style>
