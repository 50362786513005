export const delegationRegistryAbiV1 = [
  {
    inputs: [
      { internalType: 'address', name: 'delegate', type: 'address' },
      { internalType: 'address', name: 'vault', type: 'address' },
    ],
    name: 'checkDelegateForAll',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'delegate', type: 'address' },
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
    ],
    name: 'checkDelegateForContract',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'delegate', type: 'address' },
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'checkDelegateForToken',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'vault', type: 'address' }],
    name: 'getContractLevelDelegations',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'contract_', type: 'address' },
          { internalType: 'address', name: 'delegate', type: 'address' },
        ],
        internalType: 'struct IDelegationRegistry.ContractDelegation[]',
        name: 'contractDelegations',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'vault', type: 'address' }],
    name: 'getDelegatesForAll',
    outputs: [{ internalType: 'address[]', name: 'delegates', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
    ],
    name: 'getDelegatesForContract',
    outputs: [{ internalType: 'address[]', name: 'delegates', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'vault', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'getDelegatesForToken',
    outputs: [{ internalType: 'address[]', name: 'delegates', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'delegate', type: 'address' }],
    name: 'getDelegationsByDelegate',
    outputs: [
      {
        components: [
          { internalType: 'enum IDelegationRegistry.DelegationType', name: 'type_', type: 'uint8' },
          { internalType: 'address', name: 'vault', type: 'address' },
          { internalType: 'address', name: 'delegate', type: 'address' },
          { internalType: 'address', name: 'contract_', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        internalType: 'struct IDelegationRegistry.DelegationInfo[]',
        name: 'info',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'vault', type: 'address' }],
    name: 'getTokenLevelDelegations',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'contract_', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'address', name: 'delegate', type: 'address' },
        ],
        internalType: 'struct IDelegationRegistry.TokenDelegation[]',
        name: 'tokenDelegations',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
];

export const delegationRegistryAbiV2 = [
  {
    inputs: [],
    name: 'MulticallFailed',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
    ],
    name: 'checkDelegateForAll',
    outputs: [{ internalType: 'bool', name: 'valid', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
      { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
    ],
    name: 'checkDelegateForContract',
    outputs: [{ internalType: 'bool', name: 'valid', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
    ],
    name: 'checkDelegateForERC1155',
    outputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
      { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
    ],
    name: 'checkDelegateForERC20',
    outputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'contract_', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
    ],
    name: 'checkDelegateForERC721',
    outputs: [{ internalType: 'bool', name: 'valid', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32[]', name: 'hashes', type: 'bytes32[]' }],
    name: 'getDelegationsFromHashes',
    outputs: [
      {
        components: [
          { internalType: 'enum IDelegateRegistry.DelegationType', name: 'type_', type: 'uint8' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
          { internalType: 'address', name: 'contract_', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct IDelegateRegistry.Delegation[]',
        name: 'delegations_',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
    name: 'getIncomingDelegationHashes',
    outputs: [{ internalType: 'bytes32[]', name: 'delegationHashes', type: 'bytes32[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
    name: 'getIncomingDelegations',
    outputs: [
      {
        components: [
          { internalType: 'enum IDelegateRegistry.DelegationType', name: 'type_', type: 'uint8' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
          { internalType: 'address', name: 'contract_', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct IDelegateRegistry.Delegation[]',
        name: 'delegations_',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }],
    name: 'getOutgoingDelegationHashes',
    outputs: [{ internalType: 'bytes32[]', name: 'delegationHashes', type: 'bytes32[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }],
    name: 'getOutgoingDelegations',
    outputs: [
      {
        components: [
          { internalType: 'enum IDelegateRegistry.DelegationType', name: 'type_', type: 'uint8' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'bytes32', name: 'rights', type: 'bytes32' },
          { internalType: 'address', name: 'contract_', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct IDelegateRegistry.Delegation[]',
        name: 'delegations_',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'location', type: 'bytes32' }],
    name: 'readSlot',
    outputs: [{ internalType: 'bytes32', name: 'contents', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32[]', name: 'locations', type: 'bytes32[]' }],
    name: 'readSlots',
    outputs: [{ internalType: 'bytes32[]', name: 'contents', type: 'bytes32[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'pure',
    type: 'function',
  },
];
