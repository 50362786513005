import { apiHelpers } from '@/api/apiHelpers';

export interface IMerkleInfo {
  merkleProof: string[];
  value: number;
}
export interface ICollectorApiClient {
  getMerkleInfos(merkleTreeId: number, walletAddress: string): Promise<IMerkleInfo[]>;
}

class CollectorAPIClient implements ICollectorApiClient {
  async getMerkleInfos(merkleTreeId: number, walletAddress: string): Promise<IMerkleInfo[]> {
    return apiHelpers.fetch(
      `/public/merkleTree/${merkleTreeId}/merkleInfo?address=${walletAddress}`
    );
  }
}

export default new CollectorAPIClient();
