import { fetchWithTimeout } from '@/lib/fetchWithTimeout';

export async function getEthToUsdRate(currency = 'ETH'): Promise<number | undefined> {
  try {
    const x = await fetchWithTimeout(`https://api.coinbase.com/v2/prices/${currency}-USD/spot`, {
      method: 'GET',
    });
    if (x.status !== 200) {
      throw new Error('Non-200 response from coinbase');
    }
    const ethToUsdJson = await x.json();
    return parseFloat(ethToUsdJson.data.amount);
  } catch {
    return undefined;
  }
}

export async function getERC20ToUSDRate(erc20: string): Promise<number | undefined> {
  try {
    const x = await fetchWithTimeout(
      `https://api.coingecko.com/api/v3/coins/${erc20.toLowerCase()}`,
      {
        method: 'GET',
      }
    );
    if (x.status !== 200) {
      throw new Error('Non-200 response from coinbase');
    }
    const erc20ToUSD = await x.json();
    return parseFloat(erc20ToUSD?.market_data?.current_price?.usd ?? 0);
  } catch {
    return undefined;
  }
}
