<template>
  <div id="footer-container">
    <a class="left-side" href="https://manifold.xyz" target="_blank">
      <div id="footer-logo">
        <img src="../assets/images/manifold-logograph.svg" />
      </div>
      <span>©2023 MANIFOLD TECHNOLOGIES LTD.</span>
    </a>
    <div class="right-side">
      <a
        class="faq"
        href="https://docs.manifold.xyz/v/manifold-studio/apps/claim-page"
        target="_blank"
      >
        FAQ
      </a>
      <a class="pp" href="https://manifold.xyz/privacy/" target="_blank"> PRIVACY POLICY </a>
      <a class="tos" href="https://manifold.xyz/tos/" target="_blank"> TERMS OF SERVICE </a>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="scss" scoped>
#footer-container {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: visible;
  background-color: var(--backgroundColor);
  border-top: var(--border);
  box-sizing: border-box;
  padding: 0 10px;
  // so that it is not blocked by buy button
  margin-bottom: 70px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }

  span,
  a {
    font-family: 'Saira', sans-serif;
    text-transform: uppercase;
    font-size: 11px;
    color: var(--manifold-text--color--body);
    text-decoration: none;
  }

  .left-side {
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: transform 0.2s ease;
    transform-origin: center left;

    #footer-logo {
      margin: 0 20px 0 0;

      img {
        width: auto;
        height: 12px;
      }
    }
  }

  .right-side {
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    overflow: visible;
    transition: transform 0.2s ease;
    transform-origin: center right;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 4px 0;

    .left-side {
      margin: 0;
    }

    .right-side {
      justify-content: space-between;
      align-items: space-between;
    }
  }
}
</style>
