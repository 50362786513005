import {
  computed,
  isRef,
  onUnmounted,
  readonly,
  type Ref,
  ref,
  unref,
  watch,
  watchEffect,
} from 'vue';
import { type ClientTheme } from '@/store/claimStore';
import { useWindowSize } from './windowSize';

/** FPS value */
const DEFAULT_FRAME_RATE = 60;
const MS_PER_FRAME = 1000 / DEFAULT_FRAME_RATE;
// TODO(custom-animation): add initial position, velocity to config
/** default x velocity */
const DX_PX = 2;
/** default y velocity */
const DY_PX = 0;

interface Coordinate {
  x: number;
  y: number;
}

interface AnimationParams {
  position: Coordinate;
  velocity: Coordinate;
  frames: number;
}

type AnimationConfig = Required<Required<ClientTheme>['animation']> | null;

type AnimationResult = Omit<AnimationParams, 'frames'>;

type Animation = (params: AnimationParams, maximum: Coordinate) => AnimationResult;

/** simple animation that bounces around the screen */
function bounce(
  { position, velocity, frames }: AnimationParams,
  maximum: Coordinate
): AnimationResult {
  let x = position.x + velocity.x * frames;
  let y = position.y + velocity.y * frames;
  const newVelocity = { ...velocity };

  // clamp x position + switch directions
  if (x > maximum.x || x < 0) {
    x = Math.max(Math.min(x, maximum.x), 0);
    newVelocity.x = -velocity.x;
  }

  // clamp y position + switch directions
  if (y > maximum.y || y < 0) {
    y = Math.max(Math.min(y, maximum.y), 0);
    newVelocity.y = -velocity.y;
  }
  return { position: { x, y }, velocity: newVelocity };
}

/** simple animation that goes in a diagonal along the screen */
function diagonal(
  { position, velocity, frames }: AnimationParams,
  maximum: Coordinate
): AnimationResult {
  return {
    position: {
      x: (position.x + velocity.x * frames) % maximum.x,
      y: (position.y + velocity.y * frames) % maximum.y,
    },
    velocity,
  };
}

const AnimationFunctions: Record<string, Animation> = {
  bounce,
  diagonal,
};

interface AnimationState {
  /** top offset in px */
  x: Ref<number>;
  /** left offset in px */
  y: Ref<number>;
  /** width of the animation in px */
  width: Ref<number>;
  /** height of the animation in px */
  height: Ref<number>;
  /** whether or not the animation is currently playing */
  playing: Ref<boolean>;
}

/**
 * Composable that handles the animation of the client image
 *
 * @param animationConfig - the animation configuration
 * @returns {AnimationState} reactive refs to the animation's properties
 */
export function useClientAnimation(
  animationConfig: Ref<AnimationConfig> | AnimationConfig
): AnimationState {
  const x = ref(0);
  const y = ref(0);
  // using refs here so we can leverage computed on maximum coordinates
  const configWidth = ref(0);
  const configHeight = ref(0);
  const configPlayOnMobile = ref(false);
  let config: AnimationConfig = null;

  /** animation strategy */
  const animationFunction = AnimationFunctions.bounce;
  const velocity: Coordinate = { x: DX_PX, y: DY_PX };

  // TODO(custom-animation): add on resize functions for different animations
  const { innerWidth, innerHeight, isMobile } = useWindowSize();

  const animationWidth = computed(() => {
    return isMobile.value ? configWidth.value / 2 : configWidth.value;
  });
  const animationHeight = computed(() => {
    return isMobile.value ? configHeight.value / 2 : configHeight.value;
  });
  /** maximum x coordinate for the animation */
  const maxX = computed(() => Math.max(innerWidth.value - animationWidth.value, 0));
  /** maximum y coordinate for the animation */
  const maxY = computed(() => Math.max(innerHeight.value - animationHeight.value, 0));
  let animationRequestId = 0;
  const playAnimation = computed<boolean>(() => {
    if (!configHeight.value || !configWidth.value) {
      // no height, width defined
      return false;
    }
    if (configPlayOnMobile.value) {
      // screen size does not matter => always play
      return true;
    }
    // only play if not on mobile
    return !isMobile.value;
  });
  let lastAnimationTimeMs = 0;

  /** setup animation values that depend on the config */
  function _setupAnimation() {
    config = unref(animationConfig);
    if (!config) {
      // reset
      configHeight.value = 0;
      configWidth.value = 0;
      configPlayOnMobile.value = false;
      return;
    }
    configHeight.value = config.height;
    configWidth.value = config.width;
    configPlayOnMobile.value = config.playOnMobile;
  }

  // NOTE: custom shibuya -- ensure y = windowHeight - height;
  watch(
    () => maxY.value,
    () => {
      y.value = maxY.value;
    },
    { immediate: true }
  );

  /** determine whether to run/stop the animation */
  watch(() => playAnimation.value, handleAnimationRequest, { immediate: true });

  function handleAnimationRequest() {
    if (playAnimation.value) {
      animationRequestId = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(animationRequestId);
      animationRequestId = 0;
      // pause the animation at current position
      lastAnimationTimeMs = 0;
    }
  }

  /** animate the image position with the given animation function */
  function animate(currentTimeMs: number) {
    if (lastAnimationTimeMs === 0) {
      // starts the animation
      lastAnimationTimeMs = currentTimeMs;
    }
    const elapsedMs = currentTimeMs - lastAnimationTimeMs;
    const elapsedFrames = Math.floor(elapsedMs / MS_PER_FRAME);
    if (elapsedFrames > 0) {
      // animate
      const { position, velocity: newVelocity } = animationFunction(
        {
          position: { x: x.value, y: y.value },
          velocity,
          frames: elapsedFrames,
        },
        { x: maxX.value, y: maxY.value }
      );
      x.value = position.x;
      y.value = position.y;
      velocity.x = newVelocity.x;
      velocity.y = newVelocity.y;
      // only update the last timestamp if the animation was performed
      lastAnimationTimeMs = currentTimeMs;
    }

    handleAnimationRequest();
  }

  // make sure that composable is reactive to changes in the animation config
  if (isRef(animationConfig)) {
    watchEffect(_setupAnimation);
  } else {
    _setupAnimation();
  }

  onUnmounted(() => {
    if (animationRequestId) {
      cancelAnimationFrame(animationRequestId);
      animationRequestId = 0;
    }
  });

  return {
    x: readonly(x),
    y: readonly(y),
    height: readonly(animationHeight),
    width: readonly(animationWidth),
    playing: readonly(playAnimation),
  };
}
