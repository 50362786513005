import { TransactionResponse } from '@ethersproject/abstract-provider';
import { useMutation, UseMutationOptions } from '@tanstack/vue-query';
import { readonly, ref, unref } from 'vue';
import { detectManifoldEthereumProvider } from '@manifoldxyz/frontend-provider-utils';
import { getBuyTransactionData } from '@/api/reservoir';
import { sleep } from '@/lib/claimUtil';
import { useClaimStore } from '@/store/claimStore';

type UseCollectionOptions = UseMutationOptions<
  { tx: TransactionResponse | undefined },
  unknown,
  { amount: number },
  unknown
>;

export function useCollect(options?: UseCollectionOptions) {
  const store = useClaimStore();

  const tx = ref<TransactionResponse>();
  const txState = ref<'idle' | 'confirming' | 'success' | 'error'>('idle');
  const copyTx = readonly(tx);
  const copyTxState = readonly(txState);

  const {
    mutate: collect,
    mutateAsync: collectAsync,
    ...rest
  } = useMutation(
    async ({ amount }: { amount: number }) => {
      txState.value = 'idle';

      if (!store.isConnected || !store.mintForWallet || !store.walletAddress) {
        throw new Error('Connect your wallet to continue');
      }

      const floorPriceTokenId = store.floorPriceTokenId?.toString();
      if (!floorPriceTokenId) {
        throw new Error('Lowest floor price token ID is not valid');
      }

      const chainId = store.networkId;
      if (!chainId) {
        throw new Error('Chain ID is not valid');
      }

      const ethersProvider = (
        await detectManifoldEthereumProvider({ initialized: true })
      )?.provider();
      const signer = await ethersProvider?.getSigner();

      if (!signer) {
        throw new Error('Signer is not valid');
      }

      const txData = await getBuyTransactionData(
        store.creatorContractAddress,
        chainId,
        floorPriceTokenId,
        store.walletAddress,
        amount
      );
      if (!txData) {
        throw new Error('Failed to get transaction data');
      }

      const _tx = await signer?.sendTransaction(txData);

      tx.value = _tx;
      txState.value = 'confirming';

      if (!store.isProviderAvailable) {
        // HACK: since claims supports minting without a provider
        // (e.g. walletconnect) we don't have the ability
        // to wait until the transaction has been mined.
        // Pausing here allows us to have a good chance
        // that the block will be mined before a ui
        // refresh happens which avoids a number of
        // confusing ui edge cases.
        await sleep(60_000);
      } else {
        await _tx?.wait(1);
      }

      store.refreshWeb3State();
      txState.value = 'success';

      return {
        tx: unref(tx),
      };
    },
    {
      ...options,
      onError: (err, variables, context) => {
        txState.value = 'error';
        options?.onError?.(err, variables, context);
      },
    }
  );

  return {
    ...rest,
    collect,
    collectAsync,
    tx: copyTx,
    txState: copyTxState,
  };
}
