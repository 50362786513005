import { computed, onMounted, onUnmounted, readonly, type Ref, ref } from 'vue';

const MOBILE_BREAKPOINT_WIDTH = 768;

interface WindowSize {
  innerWidth: Ref<number>;
  innerHeight: Ref<number>;
  /** whether or not current window screen is considered to be mobile */
  isMobile: Ref<boolean>;
}

/**
 * Returns the current window size properties as refs.
 *
 * @returns {WindowSize} - reactive window props.
 */
export function useWindowSize(): WindowSize {
  const innerWidth = ref(window.innerWidth);
  const innerHeight = ref(window.innerHeight);
  const isMobile = computed(() => innerWidth.value < MOBILE_BREAKPOINT_WIDTH);

  function onResize() {
    innerWidth.value = window.innerWidth;
    innerHeight.value = window.innerHeight;
  }

  onMounted(() => {
    window.addEventListener('resize', onResize);
    onResize();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResize);
  });

  return {
    innerWidth: readonly(innerWidth),
    innerHeight: readonly(innerHeight),
    isMobile: readonly(isMobile),
  };
}
