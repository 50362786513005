import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { delegationRegistryAbiV1, delegationRegistryAbiV2 } from '@/abis';
import { _callReadWeb3WithManifoldBridgeFallback } from './utils/web3Call';

export enum DelegationType {
  NONE,
  ALL,
  CONTRACT,
  TOKEN,
}
export interface IDelegate {
  contract_: string;
  delegate: string;
  tokenId: BigNumber;
  type_: DelegationType;
  vault: string;
}
export class DelegationRegistryContract {
  private networkId: number;
  private delegationContractAddressV1: string;
  private checkV1: boolean;
  private delegationContractAddressV2: string;
  private checkV2: boolean;

  constructor(
    networkId: number,
    delegationContractAddressV1: string,
    checkV1: boolean,
    delegationContractAddressV2: string,
    checkV2: boolean
  ) {
    this.networkId = networkId;
    this.delegationContractAddressV1 = delegationContractAddressV1;
    this.delegationContractAddressV2 = delegationContractAddressV2;
    this.checkV1 = checkV1;
    this.checkV2 = checkV2;
  }

  protected _getContractInstanceV1(): Contract {
    const contract = window.ManifoldEthereumProvider.contractInstance(
      this.delegationContractAddressV1,
      delegationRegistryAbiV1,
      false
    );
    if (!contract) {
      throw new Error('No contract instance available, please refresh this page to try again');
    }
    return contract;
  }

  protected _getContractInstanceV2(): Contract {
    const contract = window.ManifoldEthereumProvider.contractInstance(
      this.delegationContractAddressV2,
      delegationRegistryAbiV2,
      false
    );
    if (!contract) {
      throw new Error('No contract instance available, please refresh this page to try again');
    }
    return contract;
  }

  async getDelegatesForContract(vault: string, contractAddress: string): Promise<string[]> {
    let delegatesV1: string[] = [];
    let delegatesV2: string[] = [];
    if (this.checkV1) {
      delegatesV1 = await _callReadWeb3WithManifoldBridgeFallback(this.networkId, {
        contractAddress: this.delegationContractAddressV1,
        abi: delegationRegistryAbiV1,
        functionName: 'getDelegatesForContract',
        args: [vault, contractAddress],
      });
    }
    if (this.checkV2) {
      const rawDelegatesV2 = await _callReadWeb3WithManifoldBridgeFallback(this.networkId, {
        contractAddress: this.delegationContractAddressV2,
        abi: delegationRegistryAbiV2,
        functionName: 'getOutgoingDelegations',
        args: [vault],
      });
      delegatesV2 = rawDelegatesV2
        .filter(
          (delegate: any) =>
            delegate.type_ === 2 &&
            delegate.contract_.toLowerCase() === contractAddress.toLowerCase()
        )
        .map((delegate: any) => {
          return delegate.to;
        });
    }
    return delegatesV1.concat(delegatesV2);
  }

  async getDelegatesForAll(vault: string): Promise<string[]> {
    let delegatesV1: string[] = [];
    let delegatesV2: string[] = [];
    if (this.checkV1) {
      delegatesV1 = await _callReadWeb3WithManifoldBridgeFallback(this.networkId, {
        contractAddress: this.delegationContractAddressV1,
        abi: delegationRegistryAbiV1,
        functionName: 'getDelegatesForAll',
        args: [vault],
      });
    }
    if (this.checkV2) {
      const rawDelegatesV2 = await _callReadWeb3WithManifoldBridgeFallback(this.networkId, {
        contractAddress: this.delegationContractAddressV2,
        abi: delegationRegistryAbiV2,
        functionName: 'getOutgoingDelegations',
        args: [vault],
      });
      delegatesV2 = rawDelegatesV2
        .filter((delegate: any) => delegate.type_ === 1)
        .map((delegate: any) => {
          return delegate.to;
        });
    }
    return delegatesV1.concat(delegatesV2);
  }

  async getDelegationsByDelegate(delegate: string): Promise<IDelegate[]> {
    let delegationsV1: IDelegate[] = [];
    let delegationsV2: IDelegate[] = [];
    if (this.checkV1) {
      delegationsV1 = await _callReadWeb3WithManifoldBridgeFallback(this.networkId, {
        contractAddress: this.delegationContractAddressV1,
        abi: delegationRegistryAbiV1,
        functionName: 'getDelegationsByDelegate',
        args: [delegate],
      });
    }

    if (this.checkV2) {
      const rawDelegationsV2 = await _callReadWeb3WithManifoldBridgeFallback(this.networkId, {
        contractAddress: this.delegationContractAddressV2,
        abi: delegationRegistryAbiV2,
        functionName: 'getIncomingDelegations',
        args: [delegate],
      });
      delegationsV2 = rawDelegationsV2.map((delegation: any) => {
        return {
          type_: delegation.type_,
          vault: delegation.from,
          delegate: delegation.to,
          contract_: delegation.contract_,
          tokenId: delegation.tokenId,
        };
      });
    }
    return delegationsV1.concat(delegationsV2);
  }
}
