import { FEE_ADDRESS, FEE_PER_MINT } from '@/common/constants';
import { fetchWithTimeout } from '@/lib/fetchWithTimeout';

// @see @reservoir0x/reservoir-sdk
type Execute = {
  requestId?: string;
  errors?: { message?: string; orderId?: string }[];
  preview?: any;
  path: string;
  error?: string; // Manually added client error
  steps: {
    error?: string;
    errorData?: any;
    action: string;
    description: string;
    kind: 'transaction' | 'signature';
    id: string;
    items?: {
      status: 'complete' | 'incomplete';
      data?: any;
      orderIndexes?: number[];
      orderIds?: string[];
      // Manually added
      error?: string;
      txHash?: string;
      orderData?: {
        crossPostingOrderId?: string;
        orderId: string;
        orderIndex: string;
      }[];
      transfersData?: any['/transfers/bulk/v1']['get']['responses']['200']['schema']['transfers'];
    }[];
  }[];
};

type TokenResponse = {
  token: {
    name: string;
    kind: string;
    tokenId: string;
    description: string;
  };
  market: {
    floorAsk: {
      price: {
        amount: {
          decimal: number;
        };
      };
    };
  };
};

/**
 * @param chainId
 * @returns base url for reservoir api
 * @see https://docs.reservoir.tools/reference/supported-chains
 */
export function getBaseReservoirURL(chainId: number): string | undefined {
  switch (chainId) {
    case 1:
      return 'https://api.reservoir.tools';
    case 10:
      return 'https://api-optimism.reservoir.tools';
    case 11155111:
      return 'https://api-sepolia.reservoir.tools';
    case 137:
      return 'https://api-polygon.reservoir.tools';
    default:
      return undefined;
  }
}

export async function getFloorPrices(
  contractAddress: string,
  chainId: number
): Promise<{ [key: string]: number } | undefined> {
  try {
    const baseUrl = getBaseReservoirURL(chainId);
    if (!baseUrl) {
      throw new Error('Unsupported chain ID for reservoir');
    }
    const response = await fetchWithTimeout(
      `${baseUrl}/tokens/floor/v1?collection=${contractAddress}`,
      {
        method: 'GET',
      }
    );
    if (response.status !== 200) {
      throw new Error('Non-200 response from reservoir');
    }
    const { tokens } = await response.json();
    return tokens;
  } catch {
    return undefined;
  }
}

// TODO: investigate collections / tokens endpoints to match tokenIds from floor prices ep
// to they match the claim currently using reservoir unless there is a mani ep
/**
 * Temporary solution to screen tokens returned for floor price
 * that they match the claim by name
 * @param contractAddress creatorContractAddress
 * @param tokenIds array of tokens (will return in same order)
 * @param chainId chainId
 * @returns array of tokens with metadata
 */
export async function getTokensMetadata(
  contractAddress: string,
  tokenIds: [string, number][],
  chainId: number
): Promise<TokenResponse[]> {
  if (!tokenIds.length) {
    return [];
  }
  try {
    const baseUrl = getBaseReservoirURL(chainId);
    if (!baseUrl) {
      throw new Error('Unsupported chain ID for reservoir');
    }
    const response = await fetchWithTimeout(
      `${baseUrl}/tokens/v6?tokens=${tokenIds
        .map((tokenId) => `${contractAddress}:${tokenId[0]}&tokens=`)
        .join('')
        .slice(0, -8)}`,
      {
        method: 'GET',
      }
    );
    if (response.status !== 200) {
      throw new Error('Non-200 response from reservoir');
    }
    const { tokens } = await response.json();
    if (Array.isArray(tokens)) {
      return tokens;
    } else {
      return [];
    }
  } catch {
    return [];
  }
}

interface ReservoirTransactionData {
  from: string;
  to: string;
  data: string;
  value: string;
}

export async function getBuyTransactionData(
  contractAddress: string,
  chainId: number,
  tokenId: string,
  taker: string,
  amount: number
): Promise<ReservoirTransactionData | undefined> {
  const url = `${getBaseReservoirURL(chainId)}/execute/buy/v7`;
  const tokenOrCollection =
    amount > 1 ? { collection: contractAddress } : { token: `${contractAddress}:${tokenId}` };
  const data = JSON.stringify({
    items: [
      {
        quantity: amount,
        ...tokenOrCollection,
      },
    ],
    onlyPath: false,
    normalizeRoyalties: true,
    allowInactiveOrderIds: false,
    feesOnTop: [`${FEE_ADDRESS}:${FEE_PER_MINT.mul(amount)}`], // Manifold Fee
    partial: false,
    skipBalanceCheck: false,
    excludeEOA: false,
    source: 'manifold.xyz', // Seaport Order Attribution
    taker, // Address of the user who is buying
  });
  try {
    const response = await fetchWithTimeout(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    });
    if (response.status !== 200) {
      const res = await response.json();

      // console.log('response', response.body.json());
      switch (res?.message) {
        case 'Unable to fill requested quantity':
          throw new Error('Unable to fill requested quantity');
        case 'No fillable orders (taker cannot fill own orders)':
          throw new Error('Cannot fill own order');
        default:
          throw new Error('Non-200 response from reservoir');
      }
    }
    const res: Execute = await response.json();
    const { steps } = res;

    // if hasn't been approved yet, return signature step
    if (steps.length) {
      const signatures = steps.filter(
        (step: any) => step.kind === 'signature' && step.items && step?.items.length > 0
      );
      // ignore reservoir signatures with empty data
      if (signatures.length > 0 && signatures[0].items) {
        return signatures[0].items[0]?.data;
      }

      const sales = steps.filter(
        (step: any) => step?.id === 'sale' && step?.items[0]?.status === 'incomplete'
      );

      if (sales.length > 0) {
        return sales && sales[0].items && sales[0].items[0].data;
      }
    }
    throw new Error('Invalid step processing error');
  } catch (e) {
    throw new Error('Txn error:' + e);
  }
}
