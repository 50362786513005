import { Contract } from '@ethersproject/contracts';
import { getManifoldBridgeProvider } from './providers';

export interface ContractReadCallParams {
  contractAddress: string;
  abi: any[];
  functionName: string;
  args: any[];
}

export async function _callReadWeb3WithManifoldBridgeFallback(
  networkId: number,
  contractCallParams: ContractReadCallParams
): Promise<any> {
  try {
    const provider = window.ManifoldEthereumProvider.provider();
    if (!provider) {
      // No available provider failure scenario, use our bridge provider
      throw new Error('No provider available, attempting to use manifold bridge provider');
    }

    // We have a web3timeout race because there are certain situations where
    // web3 requests will hang.  e.g. Safari websockets or Infura rate limiting
    // ref: https://developer.apple.com/forums/thread/679576
    // res: https://github.com/tilt-dev/tilt/issues/4746
    const web3timeout = new Promise((resolve) => setTimeout(resolve, 1500));
    // eslint-disable-next-line no-async-promise-executor
    const web3result = new Promise(async (resolve) => {
      try {
        resolve(
          await _getContractInstance(
            networkId,
            contractCallParams.contractAddress,
            contractCallParams.abi,
            false,
            false,
            false
          )[contractCallParams.functionName](...contractCallParams.args)
        );
      } catch {
        resolve(undefined);
      }
    });
    const result: any = await Promise.race([web3timeout, web3result]);
    if (result === undefined) {
      // Fallback provider failure scenario, use our bridge provider
      throw new Error('No provider available, attempting to use manifold bridge provider');
    }
    return result;
  } catch (e) {
    // try getting from bridge provider instead
    return await _getContractInstance(
      networkId,
      contractCallParams.contractAddress,
      contractCallParams.abi,
      false,
      true,
      false
    )[contractCallParams.functionName](...contractCallParams.args);
  }
}

export function _getContractInstance(
  networkId: number,
  contractAddress: string,
  abi: any[],
  withSigner = false,
  bridge = false,
  unchecked = false
): Contract {
  if (bridge) {
    return new Contract(contractAddress, abi, getManifoldBridgeProvider(networkId));
  }
  const contract = window.ManifoldEthereumProvider.contractInstance(
    contractAddress,
    abi,
    withSigner,
    unchecked
  );
  if (!contract) {
    throw new Error('No contract instance available, please refresh this page to try again');
  }
  return contract;
}
