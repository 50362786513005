import { Interface } from '@ethersproject/abi';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from '@ethersproject/bignumber';
import { useMutation, UseMutationOptions } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import { ref, unref } from 'vue';
import { erc721Abi, erc1155Abi } from '@/abis';
import { apiHelpers, signatureApiHelpers } from '@/api/apiHelpers';
import { sleep } from '@/lib/claimUtil';
import { SigMintError, SignatureSchema, useClaimStore } from '@/store/claimStore';

type UseMintOptions = UseMutationOptions<
  { tx: TransactionResponse; tokenIds: BigNumber[] | undefined },
  unknown,
  { amount: number },
  unknown
>;

export function useMint(options?: UseMintOptions) {
  const store = useClaimStore();

  const tx = ref<TransactionResponse>();
  const txState = ref<'idle' | 'confirming' | 'success' | 'error'>('idle');
  const tokenIds = ref<BigNumber[]>();
  const { sigMintError } = storeToRefs(store);

  const {
    mutate: mint,
    mutateAsync: mintAsync,
    ...rest
  } = useMutation(
    async ({ amount }: { amount: number }) => {
      txState.value = 'idle';

      if (!store.isConnected || !store.mintForWallet || !store.walletAddress) {
        throw new Error('Connect your wallet to continue');
      }

      const contractToCall = store.overrideMintExtensionAddress
        ? store.overrideMintContract
        : store.contract;

      let _tx: TransactionResponse;
      if (store.signatureSchema !== SignatureSchema.NONE) {
        try {
          let response;

          if (store.signatureSchema === SignatureSchema.IYK_TAP) {
            response = await signatureApiHelpers.post('/iyk-sign', {
              body: {
                walletAddress: store.walletAddress,
                instanceId: store.id,
                iykRef: store.iykRef,
                iykTimeoutMessage: store.iykTimeoutMessage,
                iykTimeoutSignature: store.iykTimeoutSignature,
                iykTimeoutNonce: store.iykTimeoutNonce,
                mintCount: amount,
              },
            });
          } else if (store.signatureSchema === SignatureSchema.REDEMPTION_CODES) {
            if (!store.redemptionCodeValidated) {
              throw new Error('Redemption code not verified');
            }

            response = await signatureApiHelpers.post('/redemption-sign', {
              body: {
                walletAddress: store.walletAddress,
                instanceId: store.id,
                redemptionCode: store.redemptionCode,
                mintCount: amount,
              },
            });
          }

          if (response.status === 400) {
            store.setSigMintError(SigMintError.BAD_SECRET);
          }
          if (response.status === 406) {
            store.setSigMintError(SigMintError.EXPIRED_SIGNATURE);
          }
          if (response.status === 500) {
            store.setSigMintError(SigMintError.SERVER_ERROR);
          }
          if (sigMintError.value) {
            throw new Error();
          }

          const { signature, message, nonce, expiration } = response;
          if (!(signature && message && nonce && expiration)) {
            store.setSigMintError(SigMintError.SERVER_ERROR);
            throw new Error('Missing signature data');
          }

          _tx = await contractToCall.mintSignature(
            amount,
            // store.claimableMintIndices.slice(0, amount),
            signature,
            message,
            nonce,
            expiration,
            // If erc20, then the "cost" is 0 here... (it will add the fee if applicable)
            store.erc20Address ? undefined : store.cost.mul(amount),
            store.walletAddress,
            store.mintForWallet
          );
        } catch (e) {
          console.error('Failed to sign IYK claim', e);
          throw e;
        }
      } else {
        if (amount === 1) {
          _tx = await contractToCall.mint(
            store.claimableMintIndices[0] || 0,
            store.claimableMerkleProofs[0] || null,
            // If erc20, then the "cost" is 0 here... (it will add the fee if applicable)
            store.erc20Address ? undefined : store.cost,
            store.walletAddress,
            store.mintForWallet
          );
        } else {
          _tx = await contractToCall.mintBatch(
            amount,
            store.claimableMintIndices.slice(0, amount),
            store.claimableMerkleProofs.slice(0, amount),
            // If erc20, then the "cost" is 0 here... (it will add the fee if applicable)
            store.erc20Address ? undefined : store.cost.mul(amount),
            store.walletAddress,
            store.mintForWallet
          );
        }
      }

      const transactionHash = _tx.hash;
      const event = new CustomEvent('m-claim-new-transaction', {
        detail: {
          txHash: transactionHash,
          claimInstance: store.id,
        },
      });
      window.dispatchEvent(event);
      tx.value = _tx;
      txState.value = 'confirming';

      apiHelpers
        .post('/public/instance/tx', {
          query: {
            appId: store.appId,
            instanceId: store.id,
            hash: _tx.hash,
          },
        })
        .catch(() => {
          console.error('Failed to post tx hash to public instance api');
        });

      if (!store.isProviderAvailable) {
        // HACK: since claims supports minting without a provider
        // (e.g. walletconnect) we don't have the ability
        // to wait until the transaction has been mined.
        // Pausing here allows us to have a good chance
        // that the block will be mined before a ui
        // refresh happens which avoids a number of
        // confusing ui edge cases.
        await sleep(60_000);
      } else {
        const receipt = await _tx.wait(1);

        try {
          if (receipt && receipt.logs) {
            const ids = [];
            for (const log of receipt.logs) {
              try {
                if (store.is721) {
                  const iface = new Interface(erc721Abi);
                  const parsed = iface.parseLog(log);
                  ids.push(parsed.args.tokenId);
                } else {
                  const iface = new Interface(erc1155Abi);
                  const parsed = iface.parseLog(log);
                  ids.push(parsed.args.id);
                }
              } catch (e) {
                // Do nothing, log will failed parse for events specific to extension contract
              }
            }

            tokenIds.value = ids;
          }
        } catch (e) {
          // tx is already complete; no need to throw
          console.error(e);
        }
      }

      store.refreshWeb3State();
      txState.value = 'success';

      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tx: unref(tx)!,
        tokenIds: unref(tokenIds),
      };
    },
    {
      ...options,
      onError: (err, variables, context) => {
        txState.value = 'error';
        options?.onError?.(err, variables, context);
      },
    }
  );

  return {
    ...rest,
    mint,
    mintAsync,
    tx,
    txState,
    tokenIds,
  };
}
