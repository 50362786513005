import JSConfetti from 'js-confetti';
import { onMounted, ref } from 'vue';
import { useClaimStore } from '@/store/claimStore';

/** jsConfetti singleton */
const jsConfetti = ref<JSConfetti | null>(null);

export function useConfetti() {
  const store = useClaimStore();

  onMounted(() => {
    if (!jsConfetti.value) {
      jsConfetti.value = new JSConfetti();
    }
  });

  /**
   * puts confetti on the page using the store defaults + optional overrides.
   * does nothing if jsConfetti is not initialized.
   * @param options - optional config for confetti to override defaults
   */
  function addConfetti(options: Parameters<JSConfetti['addConfetti']>['0'] = {}) {
    if (!jsConfetti.value) {
      return;
    }

    jsConfetti.value.addConfetti({
      emojis: store.checkoutEmojis,
      confettiRadius: 90,
      confettiNumber: 200,
      ...options,
    });
  }
  return {
    addConfetti,
  };
}
