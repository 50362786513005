import { fetchWithTimeout } from '@/lib/fetchWithTimeout';

const getTwitterHandle = async (address: string) => {
  try {
    const userData = await fetchWithTimeout(
      `https://identity.api.manifoldxyz.dev/badges?walletAddress=${address}`,
      {
        method: 'GET',
      }
    );

    const userDataJson = await userData.json();

    if (userDataJson.twitterUsername) {
      return userDataJson.twitterUsername;
    }

    return null;
  } catch {
    return null;
  }
};

export { getTwitterHandle };
