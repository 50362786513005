export const APPS_BACKEND = 'https://apps.api.manifoldxyz.dev';
export const appsApiBaseUrl =
  process.env.NODE_ENV === 'development' && process.env.VUE_APP_APPS_BACKEND
    ? process.env.VUE_APP_APPS_BACKEND
    : `${APPS_BACKEND}`;

export const SIGNATURE_SERVICE = 'https://signature.api.manifoldxyz.dev';
export const signatureApiBaseUrl =
  process.env.NODE_ENV === 'development' && process.env.VUE_APP_SIGNATURE_SERVICE
    ? process.env.VUE_APP_SIGNATURE_SERVICE
    : `${SIGNATURE_SERVICE}`;

class APIHelpers {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getHeaders() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } as any;

    return headers;
  }

  async fetch(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const requestOptions = {
        method: 'GET',
        headers: await this.getHeaders(),
        ...options,
      };

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }

      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        let result;
        try {
          result = await response.json();
        } catch (e) {
          throw response;
        }

        throw result;
      } else {
        throw error;
      }
    }
  }

  async put(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const requestOptions = {
        method: 'PUT',
        headers: await this.getHeaders(),
        ...options,
      } as any;

      if (options.body) {
        requestOptions.body = JSON.stringify(options.body);
      }

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }

  async patch(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const requestOptions = {
        method: 'PATCH',
        headers: await this.getHeaders(),
        ...options,
      } as any;

      if (options.body) {
        requestOptions.body = JSON.stringify(options.body);
      }

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }

  async post(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }
      const requestOptions = {
        method: 'POST',
        headers: await this.getHeaders(),
        ...options,
      } as any;

      if (options.body) {
        requestOptions.body = JSON.stringify(options.body);
      }

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }

  async delete(path: string, options: any = {}) {
    try {
      let url = this.baseUrl + path;

      if (options.query) {
        const queryString = new URLSearchParams(options.query).toString();
        url = url + '?' + queryString;
      }

      const headers = await this.getHeaders();
      delete headers['Content-Type'];

      const requestOptions = {
        method: 'DELETE',
        headers,
        ...options,
      } as any;

      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw response;
      }
      const result = await response.json();

      return result;
    } catch (error: any) {
      if (error.json) {
        const response = error;
        try {
          const result = await response.json();
          throw result;
        } catch (e) {
          throw response;
        }
      } else {
        throw error;
      }
    }
  }
}

export const apiHelpers = new APIHelpers(appsApiBaseUrl);
export const signatureApiHelpers = new APIHelpers(signatureApiBaseUrl);
