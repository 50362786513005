import { isRef, Ref, ref, unref, watchEffect } from 'vue';
import {
  DelegationRegistryContract,
  DelegationType,
  IDelegate,
} from '@/classes/delegationRegistryContract';
import { EXTENSION_TRAITS } from '@/common/constants';

export interface IDelegatesOptions {
  checkWalletsEligibility?: (address: string[]) => Promise<string[]>;
}

export function useDelegates(
  networkId: Ref<number | undefined> | number,
  delegatedWallet: Ref<string | undefined> | string | undefined,
  extensionAddress: Ref<string | undefined> | string | undefined,
  delegationRegistryAddressV1: string,
  delegationRegistryAddressV2: string,
  options: IDelegatesOptions
) {
  const isLoading = ref(false);
  const isError = ref(false);
  const error = ref();
  const eligibleVaultWallets = ref<string[]>();
  const eligibleBadVaultWallets = ref<string[]>();
  const hasPotentiallyMisconfiguredDelegations = ref(false);

  async function getWalletEligibility() {
    if (!delegatedWallet || !unref(delegatedWallet)) {
      // delegatedWallet is supposed to eventually be truthly
      return;
    }
    if (!extensionAddress || !unref(extensionAddress)) {
      return;
    }
    const extensionAddressValue = unref(extensionAddress as string);
    const extensionTraits = EXTENSION_TRAITS[extensionAddressValue];
    const hasDelegatesV1 = extensionTraits && extensionTraits.includes('delegateV1');
    const hasDelegatesV2 = extensionTraits && extensionTraits.includes('delegateV2');

    isLoading.value = true;

    try {
      const filteredDelegations: IDelegate[] = [];
      const potentiallyMisconfiguredDelegations: IDelegate[] = [];

      if (hasDelegatesV1 || hasDelegatesV2) {
        const network = unref(networkId);
        if (!network) {
          throw new Error('Network is not defined');
        }
        const delegationRegistryContract = new DelegationRegistryContract(
          network,
          delegationRegistryAddressV1,
          hasDelegatesV1,
          delegationRegistryAddressV2,
          hasDelegatesV2
        );

        // Get delegations from delegate.cash
        const delegations = await delegationRegistryContract.getDelegationsByDelegate(
          unref(delegatedWallet as string)
        );

        // Claim extensions only accepts wallet- and (claim extension contract)-scoped delegations, so filter out the rest.
        // If we detect delegations for a wallet in the claim, but they're incorrectly scoped, warn the user they may be misconfigured.
        for (const delegation of delegations) {
          if (delegation.type_ === DelegationType.ALL) {
            filteredDelegations.push(delegation);
          } else if (delegation.type_ === DelegationType.CONTRACT) {
            const extensionAddressValue: string | undefined = extensionAddress
              ? unref(extensionAddress)
              : undefined;
            if (extensionAddressValue && delegation.contract_ === extensionAddressValue) {
              filteredDelegations.push(delegation);
            } else {
              potentiallyMisconfiguredDelegations.push(delegation);
            }
          } else {
            potentiallyMisconfiguredDelegations.push(delegation);
          }
        }
      }

      // Check wallet eligibility for claim
      const vaultWallets = [...new Set(filteredDelegations.map((delegation) => delegation.vault))];
      if (options.checkWalletsEligibility) {
        vaultWallets.push(unref(delegatedWallet as string));
        eligibleVaultWallets.value = await options.checkWalletsEligibility(vaultWallets);
      }

      // Also check eligibility of unusable delegations. If none are eligible, we don't need to warn the user.
      const badVaultWallets = [
        ...new Set(potentiallyMisconfiguredDelegations.map((delegation) => delegation.vault)),
      ];
      if (options.checkWalletsEligibility) {
        eligibleBadVaultWallets.value = await options.checkWalletsEligibility(badVaultWallets);
      }
      hasPotentiallyMisconfiguredDelegations.value = !!(
        eligibleBadVaultWallets.value && eligibleBadVaultWallets.value.length > 0
      );
    } catch (e) {
      isError.value = true;
      error.value = e;
    } finally {
      isLoading.value = false;
    }
  }

  if (isRef(delegatedWallet) || isRef(networkId)) {
    // setup reactive re-fetch if input delegatedWallet/networkId is a ref
    watchEffect(getWalletEligibility);
  } else {
    getWalletEligibility();
  }

  return {
    isLoading,
    isError,
    error,
    eligibleVaultWallets,
    hasPotentiallyMisconfiguredDelegations,
  };
}
