interface NetworkConfig {
  rpcUrls: string[];
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExplorerUrls: string[];
}

export const NETWORK_CONFIGS: { [key: number]: NetworkConfig } = {
  // use for automate adding new network to Metamask if user do no have it
  10: {
    rpcUrls: ['https://mainnet.optimism.io/'],
    chainName: 'Optimism Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'OETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://optimistic.etherscan.io/'],
  },
  8453: {
    rpcUrls: ['https://mainnet.base.org'],
    chainName: 'Base Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://basescan.org'],
  },
  137: {
    rpcUrls: ['https://polygon-rpc.com'],
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com'],
  },
};
