import { fetchWithTimeout } from './fetchWithTimeout';

type EnsResult = {
  resolved?: string | null;
};

const cacheTtl = 60 * 60 * 24 * 1000;
const cacheKeyPrefix = 'manifold/ens/';

const tryFromCache = (address: string): EnsResult | null => {
  if (!address) {
    return null;
  }

  try {
    const key = address.toLowerCase();
    const itemRaw = window.sessionStorage.getItem(`${cacheKeyPrefix}${key}`);
    if (itemRaw) {
      const item = JSON.parse(itemRaw);
      if (new Date(item.expiration) > new Date()) {
        return item;
      }
    }
  } catch (error) {
    console.warn('ENS cache lookup error:', error);
  }

  return null;
};

const cacheResult = (address: string, result: EnsResult): void => {
  const item = {
    ...result,
    expiration: new Date(Date.now() + cacheTtl),
  };

  window.sessionStorage.setItem(`${cacheKeyPrefix}${address.toLowerCase()}`, JSON.stringify(item));
};

const formatAddress = (address: string): string => {
  address = address.toLowerCase();
  if (address.indexOf('0x') >= 0) {
    return `${address.slice(0, 5)}...${address.slice(-3)}`;
  } else {
    return `0x${address.slice(0, 3)}...${address.slice(-3)}`;
  }
};

const resolveENSFromBadges = async (address: string) => {
  const cachedENS = tryFromCache(address);
  if (cachedENS) {
    return cachedENS.resolved;
  }
  try {
    const userData = await fetchWithTimeout(
      `https://identity.api.manifoldxyz.dev/badges?walletAddress=${address}`,
      {
        method: 'GET',
      }
    );

    const userDataJson = await userData.json();

    if (!userDataJson.userENS) {
      return null;
    }

    cacheResult(address, { resolved: userDataJson.userENS });
    return userDataJson.userENS || formatAddress(address);
  } catch {
    return null;
  }
};

const resolveAddressFromBadges = async (ens: string) => {
  const cachedAddress = tryFromCache(ens);
  if (cachedAddress) {
    return cachedAddress.resolved;
  }
  try {
    const userData = await fetch(
      `https://identity.api.manifoldxyz.dev/badges?walletAddress=${ens}`,
      {
        method: 'GET',
      }
    );

    const userDataJson = await userData.json();

    if (userDataJson.walletAddress) {
      cacheResult(ens, { resolved: userDataJson.walletAddress });
      return userDataJson.walletAddress;
    }
  } catch {
    return null;
  }

  return null;
};

export { formatAddress, resolveENSFromBadges, resolveAddressFromBadges };
