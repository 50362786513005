// platform constants

import { ETHEREUM_NETWORK_NAMES } from './constants';

/** supported platforms for display */
export enum DisplayPlatform {
  Foundation = 'Foundation',
  Manifold = 'Manifold',
  Rarible = 'Rarible',
  LooksRare = 'LooksRare',
  OpenSea = 'OpenSea',
  MagicEden = 'MagicEden',
}

/** props for platform link display */
interface PlatformDisplayProps {
  /** supported chain ids */
  networks: Readonly<number[]>;
  /** icon to display */
  imageProps: { src: string; class?: string };
}

export const PLATFORM_DISPLAY_PROPS: { [platform in DisplayPlatform]: PlatformDisplayProps } = {
  [DisplayPlatform.Foundation]: {
    networks: [1],
    imageProps: { src: 'icon-foundation.svg' },
  },
  [DisplayPlatform.Manifold]: {
    networks: [1, 5, 10, 8453],
    imageProps: { src: 'manifold-logograph.svg', class: 'manifold-logo' },
  },
  [DisplayPlatform.Rarible]: {
    networks: [1],
    imageProps: { src: 'icon-rarible.svg' },
  },
  [DisplayPlatform.LooksRare]: {
    networks: [1],
    imageProps: { src: 'icon-looksrare.svg' },
  },
  [DisplayPlatform.OpenSea]: {
    networks: [1, 5, 10, 137, 8453, 11155111],
    imageProps: { src: 'icon-opensea.svg' },
  },
  [DisplayPlatform.MagicEden]: {
    networks: [1],
    imageProps: { src: 'icon-magic-eden.svg' },
  },
} as const;

/** order in which to display platform links */
export const PLATFORM_DISPLAY_ORDER: DisplayPlatform[] = [
  DisplayPlatform.OpenSea,
  DisplayPlatform.MagicEden,
  DisplayPlatform.LooksRare,
  DisplayPlatform.Rarible,
  DisplayPlatform.Foundation,
  DisplayPlatform.Manifold,
];

/** calculate the opensea prefix based on network (no trailing /) */
export function getOpenSeaBaseLink(networkId: number) {
  return `https://opensea.io/assets/${ETHEREUM_NETWORK_NAMES[networkId].toLowerCase()}`;
}

/** calculate manifold gallery prefix based on network (no trailing /) */
export function getGalleryBaseLink(networkId: number) {
  if (networkId === 1) {
    return 'https://gallery.manifold.xyz';
  }
  return `https://gallery.manifold.xyz/${ETHEREUM_NETWORK_NAMES[networkId].toLowerCase()}`;
}

/** calculate the link based on the token details */
export function getPlatformLink(
  platform: DisplayPlatform,
  networkId: number,
  contractAddress: string,
  tokenId: string | null
) {
  switch (platform) {
    case DisplayPlatform.OpenSea:
      return `${getOpenSeaBaseLink(networkId)}/${contractAddress}${tokenId ? `/${tokenId}` : ''}`;
    case DisplayPlatform.MagicEden:
      if (tokenId) {
        return `https://magiceden.io/item-details/ethereum/${contractAddress}/${tokenId}`;
      }
      return `https://magiceden.io/collections/ethereum/${contractAddress}`;
    case DisplayPlatform.LooksRare:
      return `https://looksrare.org/collections/${contractAddress}${
        tokenId ? `/${tokenId}` : ''
      }?ref=9323239`;
    case DisplayPlatform.Rarible:
      if (tokenId) {
        return `https://rarible.com/token/${contractAddress}:${tokenId}`;
      }
      return `https://rarible.com/collection/${contractAddress}`;
    case DisplayPlatform.Foundation:
      return `https://foundation.app/collection/${contractAddress}`;
    case DisplayPlatform.Manifold:
      // absolutely needs tokenId
      return `${getGalleryBaseLink(networkId)}/${contractAddress}/${tokenId}`;
  }
}
